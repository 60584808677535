
// Vue reactivity
import { reactive, defineComponent, watchEffect } from 'vue';
// icons
import { person, call, mail } from 'ionicons/icons';
// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, modalController, loadingController,
        IonGrid, IonCol, IonRow,
        toastController } from '@ionic/vue';
// API services
import UserService from '@/services/UserService';
import firebase from 'firebase/app';
import 'firebase/auth';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'UserProfileFormModal',
  props: {
    editingUser: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonGrid, IonCol, IonRow, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const user = reactive({
      firstName: "",
      email: "",
    });
    watchEffect(() => {
      const { firstName, email } = props.editingUser.value;
      user.firstName = firstName;
      user.email = email;
    });
    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast, presentAlert, } = utils();
    const closeModal = async (updatedUser: any = null) => (await modalController.dismiss({ updatedUser }));
    const updateUser = async() => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        const res = await UserService.updateLoggedInUser(user, props.editingUser.value);
        presentToast(t('successUpdatePersonalInfo'), 2000);
        closeModal(res);
      } catch (e) {
        presentAlert(t('clashEmailAddress'));
      }
      loading.dismiss();
    }
    // 3. return variables & methods to be used in template HTML
    return {
      t,
      
      // icons
      mail, person, call,
      // variables
      user,
      // methods
      formatDate, closeModal, updateUser
    }
  }
});
