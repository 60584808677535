import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  async doLogin(email: string, password: any) {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  },

  doLogout() {
    firebase.auth().signOut();
  },

  async doRegister(email: string, password: any) {
    return await firebase.auth().createUserWithEmailAndPassword(email, password);
  },

  async resetPassword(email: string) {
    return await firebase.auth().sendPasswordResetEmail(email);
  },

  async updatePassword(oldPassword: any, newPassword: any) {
    try {
      const currentUser: any = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, oldPassword);
      await currentUser.reauthenticateWithCredential(credential);
      return await currentUser.updatePassword(newPassword);
    } catch (err) {
      console.error(err);
      return "wrong-password";
    }
  }
}