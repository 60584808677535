import parentAuthService from '@/app-template/lib/services/AuthService';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';

export default {
  ...parentAuthService,

  async doPhoneLogin(phone: any, password: any) {
    const data = (await firebase.app().functions('asia-east2').httpsCallable('user-signInWithPhoneAndPassword')({ phone, password })).data;
    if (data.s == 200) return firebase.auth().signInWithCustomToken(data.t);
    else throw new Error(data.m); // invalid phone / password
  },

  async checkPhoneExists(phone: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-checkPhoneExists')({ phone })).data;
  }
}